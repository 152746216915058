import React from 'react';
import { ExtraDimensionData } from '@app/data/http/quote-params.dto';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { TextField } from '@atomic/atm.text-field';
import { Body } from '@atomic/atm.typography';
import { TD, TR } from '@atomic/mol.table';
import { Hbox, Separator } from '@atomic/obj.box';
import { Form, Validators } from '@atomic/obj.form';
import { FormContext } from '../form.context';

export interface DimensionsContext {
  EXTRA_DIMENSION: ExtraDimensionData[];
  OTHERS_ATTRIBUTES: any;
}
interface DiamenterRowProps {
  shape?: string;
  noLength?: boolean;
  diameter?: number;
  side?: number;
  thickness?: number;
  width?: number;
  length?: string;
  min?: number;
  max?: number;
  amount?: number;
  index?: number;
  label?: string;
  strings: any;
  updateRow?: (value: number, index: number, field: string) => void;
  handleRemove: (index: number) => void;
}

export const DiameterRow: React.FC<DiamenterRowProps> = props => {
  const { strings } = React.useContext(FormContext);
  const stringsNewForm = strings.newForm.messages;
  const form2Dimensions = ['RECTANGULAR', 'FLAT'].includes(props.shape);
  const formSideimensions = ['SQUARE', 'HEXAGONAL', 'INGOT'].includes(props.shape);
  const initialMin = props.length ? parseFloat(props.length.split(' a ').shift()) : props.min;
  const initialMax = props.length ? parseFloat(props.length.split(' a ').pop()) : props.max;
  const [min, setMin] = React.useState(props.min);
  const [max, setMax] = React.useState(props.max);

  const convertConversationId = name => {
    const convertedName = name
      .replaceAll(' (Principal)', '')
      .replaceAll(' (Main)', '')
      .replaceAll(' ', '_')
      .toUpperCase();
    return convertedName;
  };

  const handleValue = (val, index, field) => {
    if (field === 'min') {
      setMin(val);
    } else if (field === 'max') {
      setMax(val);
    }
    props.updateRow(val, index, field);
  };

  const label =
    props.index === 0
      ? `${strings.modalDiameter.bitola} (${strings.modalDiameter.main})`
      : `${strings.modalDiameter.bitola} ${props.index + 1}`;

  return (
    <TR>
      <TD alignContent='center'>
        <Hbox.Item>
          <Hbox vAlign='flex-end'>
            <Hbox.Item noGrow>
              <Body data-testid='modal-additional-diameter-label'>{label}</Body>
              <Form.Field
                name={`ADDITIONAL_DIAMETER[${props.index}].dimensionId`}
                initialValue={convertConversationId(label)}
              >
                <TextField type='hidden' small />
              </Form.Field>
              <Form.Field name={`ADDITIONAL_DIAMETER[${props.index}].label`} initialValue={label}>
                <TextField type='hidden' small />
              </Form.Field>
            </Hbox.Item>
          </Hbox>
        </Hbox.Item>
      </TD>
      <TD alignContent='end'>
        <Hbox.Item>
          <Hbox vAlign='flex-end'>
            {form2Dimensions ? (
              <>
                <Hbox.Item noGrow>
                  <Form.Field
                    name={`WIDTH[${props.index}]`}
                    label={props.index === 0 && props.strings.largura}
                    validators={[Validators.Required(stringsNewForm.mandatoryAlert)]}
                    initialValue={props?.width}
                    hideErrorCaption
                    onValueChange={value => handleValue(value, props.index, 'width')}
                  >
                    <TextField data-testid='modal-additional-diameter-width-field' type='number' small />
                  </Form.Field>
                </Hbox.Item>
                <Body>X</Body>

                <Hbox.Item noGrow>
                  <Form.Field
                    name={`THICKNESS[${props.index}]`}
                    label={props.index === 0 && props.strings.espessura}
                    validators={[Validators.Required(stringsNewForm.mandatoryAlert)]}
                    initialValue={props?.thickness}
                    hideErrorCaption
                    onValueChange={value => handleValue(value, props.index, 'thickness')}
                  >
                    <TextField data-testid='modal-additional-diameter-thickness-field' type='number' small />
                  </Form.Field>
                </Hbox.Item>
                <Separator />
              </>
            ) : formSideimensions ? (
              <>
                <Hbox.Item noGrow>
                  <Form.Field
                    name={`SIDE[${props.index}]`}
                    label={props.index === 0 && props.strings.side}
                    validators={[Validators.Required(stringsNewForm.mandatoryAlert)]}
                    initialValue={props.side}
                    hideErrorCaption
                    onValueChange={value => handleValue(value, props.index, 'side')}
                  >
                    <TextField data-testid='modal-additional-diameter-side-field' type='number' />
                  </Form.Field>
                </Hbox.Item>
                <Separator />
              </>
            ) : (
              <>
                <Hbox.Item noGrow>
                  <Form.Field
                    name={`DIAMETER[${props.index}]`}
                    label={props.index === 0 && props.strings.diameter}
                    validators={[Validators.Required(stringsNewForm.mandatoryAlert)]}
                    initialValue={props?.diameter}
                    hideErrorCaption
                    onValueChange={value => handleValue(value, props.index, 'diameter')}
                  >
                    <TextField data-testid='modal-additional-diameter-diameter-field' type='number' />
                  </Form.Field>
                </Hbox.Item>
                <Separator />
              </>
            )}

            <Body>mm</Body>
          </Hbox>
        </Hbox.Item>
      </TD>
      {!props.noLength ? (
        <TD alignContent='end'>
          <Hbox.Item>
            <Hbox vAlign='flex-end'>
              <Hbox.Item noGrow>
                <Form.Field
                  name={`LENGTH[${props.index}].min`}
                  label={props.index === 0 && props.strings.min}
                  validators={[
                    Validators.Required(stringsNewForm.mandatoryAlert),
                    Validators.MinMoreThanMax(
                      [initialMin ? initialMin : min, initialMax ? initialMax : max],
                      stringsNewForm.alertMinMoreMax,
                    ),
                  ]}
                  onValueChange={value => handleValue(parseFloat(value), props.index, 'min')}
                  initialValue={initialMin}
                  hideErrorCaption
                >
                  <TextField data-testid='modal-additional-diameter-length-min-field' type='number' small />
                </Form.Field>
              </Hbox.Item>

              <Body>{props.strings.to}</Body>

              <Hbox.Item noGrow>
                <Form.Field
                  name={`LENGTH[${props.index}].max`}
                  label={props.index === 0 && props.strings.max}
                  validators={[
                    Validators.Required(stringsNewForm.mandatoryAlert),
                    Validators.MinMoreThanMax(
                      [initialMin ? initialMin : min, initialMax ? initialMax : max],
                      stringsNewForm.alertMinMoreMax,
                    ),
                  ]}
                  onValueChange={value => handleValue(parseFloat(value), props.index, 'max')}
                  initialValue={initialMax}
                  hideErrorCaption
                >
                  <TextField data-testid='modal-additional-diameter-legth-max-field' type='number' small />
                </Form.Field>
              </Hbox.Item>
              <Body>mm</Body>
            </Hbox>
          </Hbox.Item>
        </TD>
      ) : null}

      <TD alignContent='end'>
        <Hbox.Item>
          <Hbox vAlign='flex-end'>
            <Hbox.Item noGrow>
              <Form.Field
                name={`AMOUNT[${props.index}]`}
                validators={[Validators.Required(stringsNewForm.mandatoryAlert)]}
                label={props.index === 0 && props.strings.consumo}
                initialValue={props?.amount}
                hideErrorCaption
                onValueChange={value => handleValue(value, props.index, 'amount')}
              >
                <TextField data-testid='modal-additional-diameter-amount-field' type='number' />
              </Form.Field>
            </Hbox.Item>
            <Body> {props.strings.consumoTon} </Body>
          </Hbox>
        </Hbox.Item>
      </TD>
      <TD alignContent='end'>
        {props.index > 0 ? (
          <Button
            data-testid='modal-additional-diameter-remove-line'
            size='small'
            kind='link'
            onClick={() => props.handleRemove(props.index)}
            title={props.strings.remove}
          >
            <FaIcon.Trash />
          </Button>
        ) : null}
      </TD>
    </TR>
  );
};
