import {
  AdminBPsFilterOptionsId,
  AdminBPsFilterOptionsLabel,
  ElegibilityExternalName,
  ElegibilityInternalName,
  IAdminTab,
  IBpOption,
  IRole,
  LanguageOptionsId,
  LanguageOptionsLabel,
  NPSGroupId,
  NPSGroupLabel,
  ProfileTypes,
  ProfileTypesId,
  TabDisplayName,
  TabInternalName,
} from '@app/models/admin.model';

export const LangOptions = [
  {
    id: LanguageOptionsId.Portuguese,
    label: LanguageOptionsLabel.Portuguese,
  },
  {
    id: LanguageOptionsId.English,
    label: LanguageOptionsLabel.English,
  },
  {
    id: LanguageOptionsId.Spanish,
    label: LanguageOptionsLabel.Spanish,
  },
];

export const ProfileTypesOptions = [
  {
    id: ProfileTypesId.Client,
    label: ProfileTypes.Client,
  },
  {
    id: ProfileTypesId.Epp,
    label: ProfileTypes.Epp,
  },
  {
    id: ProfileTypesId.Ped,
    label: ProfileTypes.Ped,
  },
  {
    id: ProfileTypesId.Rtc,
    label: ProfileTypes.Rtc,
  },
  {
    id: ProfileTypesId.Gq,
    label: ProfileTypes.Gq,
  },
  {
    id: ProfileTypesId.Admin,
    label: ProfileTypes.Admin,
  },
  {
    id: ProfileTypesId.Seller,
    label: ProfileTypes.Seller,
  },
  {
    id: ProfileTypesId.Scm,
    label: ProfileTypes.Scm,
  },
  {
    id: ProfileTypesId.Squad,
    label: ProfileTypes.Squad,
  },
];

export const ProfileTypesOptionsSelect = [
  {
    value: ProfileTypesId.Client,
    label: ProfileTypes.Client,
  },
  {
    value: ProfileTypesId.Epp,
    label: ProfileTypes.Epp,
  },
  {
    value: ProfileTypesId.Ped,
    label: ProfileTypes.Ped,
  },
  {
    value: ProfileTypesId.Rtc,
    label: ProfileTypes.Rtc,
  },
  {
    value: ProfileTypesId.Gq,
    label: ProfileTypes.Gq,
  },
  {
    value: ProfileTypesId.Admin,
    label: ProfileTypes.Admin,
  },
  {
    value: ProfileTypesId.Seller,
    label: ProfileTypes.Seller,
  },
  {
    value: ProfileTypesId.Scm,
    label: ProfileTypes.Scm,
  },
  {
    value: ProfileTypesId.Squad,
    label: ProfileTypes.Squad,
  },
];

export const permissionsOptions = [
  {
    id: TabInternalName.Dashboard,
    label: TabDisplayName.Dashboard,
  },
  {
    id: TabInternalName.Quotation,
    label: TabDisplayName.Quotation,
  },
  {
    id: TabInternalName.OrderInput,
    label: TabDisplayName.OrderInput,
  },
  {
    id: TabInternalName.Order,
    label: TabDisplayName.Order,
  },
  {
    id: TabInternalName.Finance,
    label: TabDisplayName.Finance,
  },
  {
    id: TabInternalName.Manifest,
    label: TabDisplayName.Manifest,
  },
  {
    id: TabInternalName.IsaManager,
    label: TabDisplayName.IsaManager,
  },
  {
    id: TabInternalName.Crm,
    label: TabDisplayName.Crm,
  },
  {
    id: TabInternalName.Surrogate,
    label: TabDisplayName.Surrogate,
  },
  {
    id: TabInternalName.LackOfLoad,
    label: TabDisplayName.LackOfLoad,
  },
  {
    id: TabInternalName.SchedulingCompleted,
    label: TabDisplayName.SchedulingCompleted,
  },
];

export const PermissionsOptionsSelect = [
  {
    value: TabInternalName.Dashboard,
    label: TabDisplayName.Dashboard,
  },
  {
    value: TabInternalName.Quotation,
    label: TabDisplayName.Quotation,
  },
  {
    value: TabInternalName.OrderInput,
    label: TabDisplayName.OrderInput,
  },
  {
    value: TabInternalName.Order,
    label: TabDisplayName.Order,
  },
  {
    value: TabInternalName.Finance,
    label: TabDisplayName.Finance,
  },
  {
    value: TabInternalName.Manifest,
    label: TabDisplayName.Manifest,
  },
  {
    value: TabInternalName.IsaManager,
    label: TabDisplayName.IsaManager,
  },
  {
    value: TabInternalName.Crm,
    label: TabDisplayName.Crm,
  },
];

export const permissions = [
  TabDisplayName.Order,
  TabDisplayName.Finance,
  TabDisplayName.Quotation,
  TabDisplayName.Manifest,
  TabDisplayName.IsaManager,
];

export const getTabs = (tabOptions: string[], tabChips: IAdminTab[]) => {
  const tabsArray = [];

  tabOptions.forEach(tabOpt => {
    const newTab = tabChips.find(t => t.tag === tabOpt);

    tabsArray.push({
      _id: newTab._id,
      tag: newTab.tag,
    });
  });

  return tabsArray;
};

export const getSelectedProfileInfo = (selectedProfile: string, roles: IRole[]) => {
  let role: IRole = null;

  if (selectedProfile && selectedProfile !== ProfileTypesId.Client && selectedProfile) {
    role = roles.filter(t => t.profile === selectedProfile)[0];
  } else {
    role = { profile: selectedProfile, tabs: [] };
  }

  return role;
};

export const clearedSelectedBPs = (bpOptions: IBpOption[]) => {
  const clearedBPOptions = [];

  if (bpOptions.length) {
    bpOptions.forEach(bp => {
      const bpAux = {
        code: bp.code,
        name: bp.name,
        id: bp.id,
        selected: false,
      };

      clearedBPOptions.push(bpAux);
    });
  }

  return clearedBPOptions;
};

export const getProfileTypeOptions = hasScmAcces => {
  let newProfileTypesOptions = [];

  if (hasScmAcces) {
    newProfileTypesOptions.push(ProfileTypesOptions[0]);
  } else {
    newProfileTypesOptions = ProfileTypesOptions;
  }

  return newProfileTypesOptions;
};

export const BpFilterOptions = [
  {
    value: AdminBPsFilterOptionsId.Bp,
    label: AdminBPsFilterOptionsLabel.Bp,
  },
  {
    value: AdminBPsFilterOptionsId.CorpGroup,
    label: AdminBPsFilterOptionsLabel.CorpGroup,
  },
];

export const NPSPageGroupOptions = [
  {
    id: NPSGroupId.First,
    label: NPSGroupLabel.First,
  },
  {
    id: NPSGroupId.Second,
    label: NPSGroupLabel.Second,
  },
  {
    id: NPSGroupId.Third,
    label: NPSGroupLabel.Third,
  },
  {
    id: NPSGroupId.Fourth,
    label: NPSGroupLabel.Fourth,
  },
  {
    id: NPSGroupId.Fifth,
    label: NPSGroupLabel.Fifth,
  },
  {
    id: NPSGroupId.Sixth,
    label: NPSGroupLabel.Sixth,
  },
];

export const NPSGroupOptions = [
  {
    id: NPSGroupId.First,
    label: NPSGroupLabel.First,
  },
  {
    id: NPSGroupId.Second,
    label: NPSGroupLabel.Second,
  },
  {
    id: NPSGroupId.Third,
    label: NPSGroupLabel.Third,
  },
  {
    id: NPSGroupId.Fourth,
    label: NPSGroupLabel.Fourth,
  },
  {
    id: NPSGroupId.Fifth,
    label: NPSGroupLabel.Fifth,
  },
  {
    id: NPSGroupId.Sixth,
    label: NPSGroupLabel.Sixth,
  },
  {
    id: NPSGroupId.Seventh,
    label: NPSGroupLabel.Seventh,
  },
];

export const ElegibilityOptionsSelect = [
  {
    value: ElegibilityInternalName.True,
    label: ElegibilityExternalName.True,
  },
  {
    value: ElegibilityInternalName.False,
    label: ElegibilityExternalName.False,
  },
];
