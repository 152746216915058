import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { scroller } from 'react-scroll';
import { LogAnalytics } from '@app/core/analytics';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { UserInfo } from '@app/models/login.model';
import { quoteIsInternalUserSelector } from '@app/modules/auth/store/auth.selectores';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { validateTokens } from '@app/modules/components/notification-center/store/thunk/validate-and-get-notification.thunk';
import { FlashMessageTargetName } from '@app/providers';
import { FlashMessageType } from '@atomic/mol.flash-message';
import { FormStrings } from './form.string';
import {
  useCallBackAttribute,
  useFormFlow,
  useFormFlowInitial,
  useGetClientsByCorporateGroup,
  useGetClientsList,
} from './hooks/formFlow.datasource';
import { useChatCreate } from './hooks/useChatCreate';
import { usePostFile } from './hooks/usePostFile';
import { attributesToDelete, conditionalAways, initialAditionalDiameter } from './utils/form.dto';
import { FieldsData, FormResultData } from './utils/mapPayloadAnalysisOld.dto';

interface FormContextState {
  formResult: FormResultData;
  enableClientSearch: boolean;
  showList: boolean;
  fieldValue: string;
  conversationId: string;
  userInfo: UserInfo;
  strings: any;
  showModalConfirmation: boolean;
  chatCreateV2: boolean;
  showModalErroNlu: boolean;
  initialized: boolean;
  dataClientsList: ClientList[];
  loadingClients: boolean;
  loadingPostFile: boolean;
  dataFlowClient: any;
  dataFlowSteel: any;
  dataFlowProduct: any;
  dataFlowGoal: any;
  dataFlowAdditional: any;
  dataFlowClientManual: any;
  dataFlowRevisionNorm: any;
  loadingFlowClient: boolean;
  loadingFlowSteel: boolean;
  loadingFlowProduct: boolean;
  loadingFlowGoal: boolean;
  loadingFormFlowAdditional: boolean;
  loadingFlowClientManual: boolean;
  loadingFlowRevisionNorm: boolean;
  modalToOpen: any;
  responseCallback: any;
  loadingCallback: boolean;
  loadingCallbackSteel: boolean;
  loadingCallbackShorter: boolean;
  loadingCallbackNorm: boolean;
  loadingCallbackChemicalComposition: boolean;
  loadingCallbackBending: boolean;
  normInfos: any;
  normInfosSelected: any;
  additionalDiameters: any;
  currentFlow: string;
  steelSearchProv: string;
  fileProv: File;
  marketClient: string;
  hasTokenChecked: boolean;
  loadingClientBps: boolean;

  setFormResult: (value: any) => void;
  handleValueAutocompleteChange: (value: string) => void;
  setEnableClientSearch: (value: boolean) => void;
  setShowList: (value: boolean) => void;
  setFieldValue: (value: string) => void;
  setConversationId: (value: string) => void;
  setShowModalConfirmation: (value: boolean) => void;
  setChatCreateV2: (value: boolean) => void;
  setShowModalErroNlu: (value: boolean) => void;
  setInitialized: (value: boolean) => void;
  show: (type: FlashMessageType, content: string, alignRight?: boolean, small?: boolean) => void;
  postFile: (any) => void;
  filterClientList: (list: ClientList[]) => ClientList[];
  handleSelectClient: (value: ClientList) => void;
  handleFocusClient: (value: string) => void;
  deleteClient: () => void;
  scrollTo: (value?: string, session?: string, openClose?: boolean) => void;
  openModals: (key: string) => void;
  setModalToOpen: (value: string) => void;
  submitModals: (stepKey: string, key: string, values: any) => void;
  callBackAttribute: (payload: any, url: string, action: string) => void;
  callBacksShorter: (any) => void;
  callBacksNorm: (any) => void;
  callBacksChemicalComposition: (any) => void;
  callBacksBending: (any) => void;
  callBacksSteel: (any) => void;
  callFlows: (flow: string) => void;
  showLoading: (key: string, stepKey: string) => void;
  calcMandatory: (steps: FieldsData[]) => number;
  setNormInfos: (value: any) => void;
  setNormInfosSelected: (value: any) => void;
  checkRulesToDelete: (field: string) => void;
  setAdditionalDiameters: (any) => void;
  setCurrentFlow: (string) => void;
  setFileProv: (File) => void;
  focusAttribute: (attrKey?: string) => void;
  setMarketClient: (string) => void;
  setHasTokenChecked: (boolean) => void;
}

export interface ClientList {
  bp?: string;
  corporateGroup?: string;
  formatList?: string;
  name?: string;
  type?: string;
  market?: string;
}

export const FormContext = React.createContext<FormContextState>(null);

export const FormProvider: React.FC = props => {
  const { userInfo, azureToken, token } = useSelector((state: RootState) => state.auth);
  const quoteIsInternalUser = useSelector(quoteIsInternalUserSelector);
  const [formResult, setFormResult] = React.useState<FormResultData>(null);
  const [enableClientSearch, setEnableClientSearch] = React.useState<boolean>(false);
  const [enableBPSearch, setEnableBPSearch] = React.useState<boolean>(false);
  const [showList, setShowList] = React.useState<boolean>(false);
  const [fieldValue, setFieldValue] = React.useState('');

  const [conversationId, setConversationId] = React.useState(null);

  const [showModalConfirmation, setShowModalConfirmation] = React.useState<boolean>(false);
  const [chatCreateV2, setChatCreateV2] = React.useState<boolean>(false);
  const [showModalErroNlu, setShowModalErroNlu] = React.useState<boolean>(false);
  const [initialized, setInitialized] = React.useState<boolean>(formResult !== null);
  const [responseCallback, setResponseCallback] = React.useState(null);
  const [additionalDiameters, setAdditionalDiameters] = React.useState(initialAditionalDiameter(userInfo.language));
  const [currentFlow, setCurrentFlow] = React.useState('');
  const [steelSearchProv, setSteelSearchProv] = React.useState(null);
  const [fileProv, setFileProv] = React.useState<File>(null);
  const [currentCorporateGroup, setCurrentCorporateGroup] = React.useState(null);
  const [marketClient, setMarketClient] = React.useState(null);

  const [normInfos, setNormInfos] = React.useState([]);
  const [normInfosSelected, setNormInfosSelected] = React.useState([]);
  const [show] = useFlashMessage(FlashMessageTargetName.APP);
  const [hasTokenChecked, setHasTokenChecked] = React.useState<boolean>(false);

  const dispatch = useDispatch<AppThunkDispatch>();

  React.useEffect(() => {
    dispatch(validateTokens()).then(() => {
      setHasTokenChecked(true);
    });
  }, []);

  useChatCreate(handleChatCreatedSucces);

  function handleChatCreatedSucces(response) {
    setChatCreateV2(response.chatCreateV2);
  }

  const { data: dataClientsList, isFetching: loadingClients } = useGetClientsList(
    {
      enabled: enableClientSearch && hasTokenChecked,
      client: fieldValue,
      language: userInfo.language,
      authorizationToken: azureToken,
      accessToken: token,
    },
    handleSuccessClientList,
  );

  function handleSuccessClientList() {
    setShowList(true);
    setEnableClientSearch(false);
  }

  const { data: dataFlowClient, isFetching: loadingFlowClient } = useFormFlowInitial(
    {
      flow: 'NEW_PRODUCT',
      step: 'CLIENT',
      language: userInfo.language,
      authorizationToken: azureToken,
      accessToken: token,
    },
    handleSuccessFlowInitial,
  );

  const { mutate: callClientBps, isLoading: loadingClientBps } = useGetClientsByCorporateGroup(handleSuccessBpsList);

  function handleSuccessFlowInitial(response) {
    setFormResult(response);
    if (userInfo?.profile?.profile === 'client') {
      setEnableBPSearch(true);

      setFormResult(prevValue => ({
        ...prevValue,
        CLIENT: {
          ...prevValue.CLIENT,
          questions: {
            ...prevValue.CLIENT.questions,
            fields: {
              ...prevValue.CLIENT.questions.fields,
              CORPORATE_CODE: {
                ...prevValue.CLIENT.questions.fields.CORPORATE_CODE,
                value: userInfo.bpList[0].corpGroup,
                permissions: {
                  ...prevValue.CLIENT.questions.fields.CORPORATE_CODE.permissions,
                  editable: false,
                },
              },
            },
          },
        },
      }));

      setCurrentCorporateGroup(userInfo.bpList[0].corpGroup);

      callClientBps({
        corporateGroup: userInfo.bpList[0].corpGroup,
        enabled: enableBPSearch && hasTokenChecked,
        language: userInfo.language,
        authorizationToken: azureToken,
        accessToken: token,
      });
    }
  }

  const { mutate: callFormFlowSteel, data: dataFlowSteel, isLoading: loadingFlowSteel } = useFormFlow(
    handleSuccessFlowSteel,
  );

  const { mutate: callFormFlowProduct, data: dataFlowProduct, isLoading: loadingFlowProduct } = useFormFlow(
    handleSuccessFlow,
  );

  const { mutate: callFormFlowGoal, data: dataFlowGoal, isLoading: loadingFlowGoal } = useFormFlow(handleSuccessFlow);

  const { mutate: callFormClientManual, data: dataFlowClientManual, isLoading: loadingFlowClientManual } = useFormFlow(
    handleSuccessFlow,
  );

  const { mutate: callFormRevisionNorm, data: dataFlowRevisionNorm, isLoading: loadingFlowRevisionNorm } = useFormFlow(
    handleSuccessFlow,
  );

  const {
    mutate: callFormFlowAdditional,
    data: dataFlowAdditional,
    isLoading: loadingFormFlowAdditional,
  } = useFormFlow(handleSuccessFlow);

  const { mutate: callBacksSteel, isLoading: loadingCallbackSteel } = useCallBackAttribute(
    handleSuccessCallBack,
    handleErrorCallback,
    handleSettled,
  );

  const { mutate: callBacksNorm, isLoading: loadingCallbackNorm } = useCallBackAttribute(
    handleSuccessCallBack,
    handleErrorCallback,
    handleSettled,
  );

  const { mutate: callBacksChemicalComposition, isLoading: loadingCallbackChemicalComposition } = useCallBackAttribute(
    handleSuccessCallBack,
    handleErrorCallback,
    handleSettled,
  );

  const { mutate: callBacksBending, isLoading: loadingCallbackBending } = useCallBackAttribute(
    handleSuccessCallBack,
    handleErrorCallback,
    handleSettled,
  );

  const { mutate: callBacksShorter, isLoading: loadingCallbackShorter } = useCallBackAttribute(
    handleSuccessCallBack,
    handleErrorCallback,
    handleSettled,
  );

  const { mutate: callBacks, isLoading: loadingCallback, reset: resetCallBack } = useCallBackAttribute(
    handleSuccessCallBack,
    handleErrorCallback,
    handleSettled,
  );

  const { mutate: postFile, isLoading: loadingPostFile } = usePostFile(
    handleSendFileSuccess,
    handleSendFileError,
    handleSettled,
  );

  function handleSuccessBpsList(data) {
    if (data.length > 1) {
      setFormResult(prevValue => ({
        ...prevValue,
        CLIENT: {
          ...prevValue.CLIENT,
          questions: {
            ...prevValue.CLIENT.questions,
            fields: {
              ...prevValue.CLIENT.questions.fields,
              CLIENT: {
                ...prevValue.CLIENT.questions.fields.CLIENT,
                mandatory: false,
              },
              WHEN_CLIENT_DATA: {
                ...prevValue.CLIENT.questions.fields.WHEN_CLIENT_DATA,
                mandatory: false,
                display: false,
              },
              BP: {
                ...prevValue.CLIENT.questions.fields.BP,
                display: true,
                options: data,
              },
            },
          },
        },
      }));
    } else {
      setFormResult(prevValue => ({
        CLIENT: {
          ...prevValue.CLIENT,
          questions: {
            ...prevValue.CLIENT.questions,
            fields: {
              ...prevValue.CLIENT.questions.fields,
              CLIENT: {
                ...prevValue.CLIENT.questions.fields.CLIENT,
                value: data[0].value,
                mandatory: false,
                permissions: {
                  ...prevValue.CLIENT.questions.fields.CLIENT.permissions,
                  editable: false,
                },
              },
              WHEN_CLIENT_DATA: {
                ...prevValue.CLIENT.questions.fields.WHEN_CLIENT_DATA,
                mandatory: false,
                display: false,
              },
              CLIENT_CODE: {
                ...prevValue.CLIENT.questions.fields.CLIENT_CODE,
                value: data[0].key,
                permissions: {
                  ...prevValue.CLIENT.questions.fields.CLIENT_CODE.permissions,
                  editable: false,
                },
              },
              BP: {
                ...prevValue.CLIENT.questions.fields.BP,
                mandatory: false,
                display: false,
              },
              FLOW: {
                ...prevValue.CLIENT.questions.fields.FLOW,
                value: null,
              },
            },
          },
        },
      }));

      if (formResult?.CLIENT?.questions?.fields?.FLOW.value?.value) {
        callFlows(formResult?.CLIENT?.questions?.fields?.FLOW.value?.value);
      }
    }
  }

  function handleSuccessFlowSteel(response) {
    setFormResult(prevValue => ({
      ...prevValue,
      ...response,
    }));

    if (!formResult?.CLIENT?.questions?.fields?.CLIENT_CUSTOM?.value) {
      dispatch(validateTokens()).then(() => {
        const payload = {
          corporateGroup: currentCorporateGroup,
          field: 'STEEL_NAME',
        };
        const url = formResult.CLIENT.questions.fields.FLOW.callback;
        callBacksSteel({
          payload,
          url,
          action: 'getSteelList',
          language: userInfo.language,
          authorizationToken: azureToken,
          accessToken: token,
        });
      });
    }
    if (userInfo.profile.profile === 'seller' || !quoteIsInternalUser) {
      setFormResult(prevValue => ({
        ...prevValue,
        STEEL: {
          ...prevValue.STEEL,
          questions: {
            ...prevValue.STEEL.questions,
            fields: {
              ...prevValue.STEEL.questions.fields,
              CHEMICAL_COMPOSITION: {
                ...prevValue.STEEL.questions.fields.CHEMICAL_COMPOSITION,
                mandatory: false,
              },
            },
          },
        },
      }));
    }
  }

  function handleSuccessFlow(response: FormResultData) {
    setFormResult(prevValue => ({
      ...prevValue,
      ...response,
    }));
    if (response?.GOAL?.questions?.fields) {
      const marketValue = marketClient === 'ME' ? 'EXPORT' : 'INTERNAL';
      const opMarket = response.GOAL.questions.fields.MARKET.options.filter(op => op.key === marketValue);
      setFormResult(prevValue => ({
        ...prevValue,
        GOAL: {
          ...prevValue.GOAL,
          questions: {
            ...prevValue.GOAL.questions,
            fields: {
              ...prevValue.GOAL.questions.fields,
              MARKET: {
                ...prevValue.GOAL.questions.fields.MARKET,
                value: { ...opMarket[0] },
                display: marketValue === 'EXPORT' ? false : true,
              },
            },
          },
        },
      }));
    }
  }

  function callFlows(flow: string) {
    formResult.STEEL = null;
    formResult.PRODUCT = null;
    formResult.GOAL = null;
    formResult.ADDITIONAL = null;
    formResult.DOCUMENT = null;
    formResult.REVISION = null;
    setFormResult(prevValue => ({
      ...prevValue,
      DOCUMENT: {
        ...prevValue.DOCUMENT,
        questions: null,
      },
      REVISION: {
        ...prevValue.REVISION,
        questions: null,
      },
      STEEL: {
        ...prevValue.STEEL,
        questions: null,
      },
      PRODUCT: {
        ...prevValue.PRODUCT,
        questions: null,
      },
      GOAL: {
        ...prevValue.GOAL,
        questions: null,
      },
      ADDITIONAL: {
        ...prevValue.ADDITIONAL,
        questions: null,
      },
    }));
    callFormClientManual({
      flow,
      step: 'DOCUMENT',
      language: userInfo.language,
      authorizationToken: azureToken,
      accessToken: token,
    });
    callFormRevisionNorm({
      flow,
      step: 'REVISION',
      language: userInfo.language,
      authorizationToken: azureToken,
      accessToken: token,
    });
    callFormFlowSteel({
      flow,
      step: 'STEEL',
      language: userInfo.language,
      authorizationToken: azureToken,
      accessToken: token,
    });
    callFormFlowProduct({
      flow,
      step: 'PRODUCT',
      language: userInfo.language,
      authorizationToken: azureToken,
      accessToken: token,
    });
    callFormFlowGoal({
      flow,
      step: 'GOAL',
      language: userInfo.language,
      authorizationToken: azureToken,
      accessToken: token,
    });
    callFormFlowAdditional({
      flow,
      step: 'ADDITIONAL',
      language: userInfo.language,
      authorizationToken: azureToken,
      accessToken: token,
    });
  }

  function callBackAttribute(payload, url, action) {
    dispatch(validateTokens()).then(() => {
      callBacks({
        payload,
        url,
        action,
        language: userInfo.language,
        authorizationToken: azureToken,
        accessToken: token,
      });
    });
  }

  const showLoading = (key: string, stepKey: string) => {
    if (formResult[stepKey]) {
      formResult[stepKey].questions.fields[key].loading = true;
      formResult[stepKey].questions.fields[key].display = true;
    }
  };
  const hideLoading = (key: string, stepKey: string) => {
    if (formResult[stepKey]) {
      formResult[stepKey].questions.fields[key].loading = false;
    }
  };

  const handleValueAutocompleteChange = (value: string) => {
    setFieldValue(value);

    if (value?.length === 3) {
      dispatch(validateTokens()).then(() => {
        setEnableClientSearch(true);
      });
    } else if (value?.length < 3) {
      setShowList(false);
      setEnableClientSearch(false);
    }
  };

  function handleSendFileSuccess(response) {
    LogAnalytics.submit({
      tipo: 'SucessoLeituraNormaForm',
    });

    if (response.length === 0) {
      LogAnalytics.submit({
        tipo: 'ErroLeituraNormaForm',
      });
      if (currentFlow === 'NEW_PRODUCT') {
        openModals('CHEMICAL_COMPOSITION');
      }
      show('alert', strings.newForm.messages.readNormError);
      //const currentStep = currentFlow === 'REVISION_NORM' ? 'REVISION' : 'STEEL';

      if (currentFlow === 'REVISION_NORM') {
        setFormResult(prevValue => ({
          ...prevValue,
          REVISION: {
            ...prevValue.REVISION,
            questions: {
              ...prevValue.REVISION.questions,
              fields: {
                ...prevValue.REVISION.questions.fields,
                CHECK_FILE_REVISION_NORM: {
                  ...prevValue.REVISION.questions.fields.CHECK_FILE_REVISION_NORM,
                  value: {
                    ...prevValue.REVISION.questions.fields.CHECK_FILE_REVISION_NORM.value,
                    key: 'NO',
                    label: strings.newForm.context.noOptionsLabel,
                    value: 'NO',
                  },
                },
                SPECIFIC_SPECIFICATION_REVISION_NORM: {
                  ...prevValue.REVISION.questions.fields.SPECIFIC_SPECIFICATION_REVISION_NORM,
                  display: true,
                },
                SPECIFICATION_REVISION_REVISION_NORM: {
                  ...prevValue.REVISION.questions.fields.SPECIFICATION_REVISION_REVISION_NORM,
                  display: true,
                },
                SPECIFICATION_DATE_REVISION_NORM: {
                  ...prevValue.REVISION.questions.fields.SPECIFICATION_DATE_REVISION_NORM,
                  display: true,
                },
                SPECIFIC_SPECIFICATION_NAME_REVISION_NORM: {
                  ...prevValue.REVISION.questions.fields.SPECIFIC_SPECIFICATION_NAME_REVISION_NORM,
                  file: null,
                  display: false,
                  value: null,
                },
              },
            },
          },
        }));
      } else if (formResult.CLIENT.questions.fields.FLOW.value.key === 'NEW_PRODUCT') {
        setFormResult(prevValue => ({
          ...prevValue,
          STEEL: {
            ...prevValue.STEEL,
            questions: {
              ...prevValue.STEEL.questions,
              fields: {
                ...prevValue.STEEL.questions.fields,
                CHECK_FILE: {
                  ...prevValue.STEEL.questions.fields.CHECK_FILE,
                  value: {
                    ...prevValue.STEEL.questions.fields.CHECK_FILE.value,
                    key: 'NO',
                    label: strings.newForm.context.noOptionsLabel,
                    value: 'NO',
                  },
                },
                SPECIFIC_SPECIFICATION_REVISION_NORM: {
                  ...prevValue.STEEL.questions.fields.SPECIFIC_SPECIFICATION_REVISION_NORM,
                  display: true,
                },
                SPECIFICATION_REVISION: {
                  ...prevValue.STEEL.questions.fields.SPECIFICATION_REVISION,
                  display: true,
                },
                SPECIFICATION_DATE: {
                  ...prevValue.STEEL.questions.fields.SPECIFICATION_DATE,
                  display: true,
                },
                SPECIFIC_SPECIFICATION_NAME: {
                  ...prevValue.STEEL.questions.fields.SPECIFIC_SPECIFICATION_NAME,
                  file: null,
                  display: false,
                  value: null,
                },
              },
            },
          },
        }));
      }
    } else {
      const hasSpecificationName = response.some(
        (item: FieldsData) =>
          item.key === 'SPECIFIC_SPECIFICATION_REVISION_NORM' || item.key === 'SPECIFIC_SPECIFICATION',
      );
      const hasSpecificationRevision = response.some(
        (item: FieldsData) =>
          item.key === 'SPECIFICATION_REVISION' || item.key === 'SPECIFICATION_REVISION_REVISION_NORM',
      );
      const hasSpecificationDate = response.some(
        (item: FieldsData) => item.key === 'SPECIFICATION_DATE' || item.key === 'SPECIFICATION_DATE_REVISION_NORM',
      );

      if (!hasSpecificationName) {
        if (currentFlow === 'REVISION_NORM') {
          setFormResult(prevValue => ({
            ...prevValue,
            REVISION: {
              ...prevValue.REVISION,
              questions: {
                ...prevValue.REVISION.questions,
                fields: {
                  ...prevValue.REVISION.questions.fields,
                  CHECK_FILE_REVISION_NORM: {
                    ...prevValue.REVISION.questions.fields.CHECK_FILE_REVISION_NORM,
                    value: {
                      ...prevValue.REVISION.questions.fields.CHECK_FILE_REVISION_NORM.value,
                      key: 'NO',
                      label: strings.newForm.context.noOptionsLabel,
                      value: 'NO',
                    },
                  },
                  SPECIFIC_SPECIFICATION_REVISION_NORM: {
                    ...prevValue.REVISION.questions.fields.SPECIFIC_SPECIFICATION_REVISION_NORM,
                    display: true,
                  },
                },
              },
            },
          }));
        } else {
          setFormResult(prevValue => ({
            ...prevValue,
            STEEL: {
              ...prevValue.STEEL,
              questions: {
                ...prevValue.STEEL.questions,
                fields: {
                  ...prevValue.STEEL.questions.fields,
                  CHECK_FILE: {
                    ...prevValue.STEEL.questions.fields.CHECK_FILE,
                    value: {
                      ...prevValue.STEEL.questions.fields.CHECK_FILE.value,
                      key: 'NO',
                      label: strings.newForm.context.noOptionsLabel,
                      value: 'NO',
                    },
                  },
                  SPECIFIC_SPECIFICATION_REVISION_NORM: {
                    ...prevValue.STEEL.questions.fields.SPECIFIC_SPECIFICATION_REVISION_NORM,
                    display: true,
                  },
                },
              },
            },
          }));
        }
      }
      if (!hasSpecificationRevision) {
        if (currentFlow === 'REVISION_NORM') {
          setFormResult(prevValue => ({
            ...prevValue,
            REVISION: {
              ...prevValue.REVISION,
              questions: {
                ...prevValue.REVISION.questions,
                fields: {
                  ...prevValue.REVISION.questions.fields,
                  CHECK_FILE_REVISION_NORM: {
                    ...prevValue.REVISION.questions.fields.CHECK_FILE_REVISION_NORM,
                    value: {
                      ...prevValue.REVISION.questions.fields.CHECK_FILE_REVISION_NORM.value,
                      key: 'NO',
                      label: strings.newForm.context.noOptionsLabel,
                      value: 'NO',
                    },
                  },
                  SPECIFICATION_REVISION_REVISION_NORM: {
                    ...prevValue.REVISION.questions.fields.SPECIFICATION_REVISION_REVISION_NORM,
                    display: true,
                  },
                },
              },
            },
          }));
        } else {
          setFormResult(prevValue => ({
            ...prevValue,
            STEEL: {
              ...prevValue.STEEL,
              questions: {
                ...prevValue.STEEL.questions,
                fields: {
                  ...prevValue.STEEL.questions.fields,
                  CHECK_FILE: {
                    ...prevValue.STEEL.questions.fields.CHECK_FILE,
                    value: {
                      ...prevValue.STEEL.questions.fields.CHECK_FILE.value,
                      key: 'NO',
                      label: strings.newForm.context.noOptionsLabel,
                      value: 'NO',
                    },
                  },
                  SPECIFICATION_REVISION: {
                    ...prevValue.STEEL.questions.fields.SPECIFICATION_REVISION,
                    display: true,
                  },
                },
              },
            },
          }));
        }
      }
      if (!hasSpecificationDate) {
        if (currentFlow === 'REVISION_NORM') {
          setFormResult(prevValue => ({
            ...prevValue,
            REVISION: {
              ...prevValue.REVISION,
              questions: {
                ...prevValue.REVISION.questions,
                fields: {
                  ...prevValue.REVISION.questions.fields,
                  CHECK_FILE_REVISION_NORM: {
                    ...prevValue.REVISION.questions.fields.CHECK_FILE_REVISION_NORM,
                    value: {
                      ...prevValue.REVISION.questions.fields.CHECK_FILE_REVISION_NORM.value,
                      key: 'NO',
                      label: strings.newForm.context.noOptionsLabel,
                      value: 'NO',
                    },
                  },
                  SPECIFICATION_DATE_REVISION_NORM: {
                    ...prevValue.REVISION.questions.fields.SPECIFICATION_DATE_REVISION_NORM,
                    display: true,
                  },
                },
              },
            },
          }));
        } else {
          setFormResult(prevValue => ({
            ...prevValue,
            STEEL: {
              ...prevValue.STEEL,
              questions: {
                ...prevValue.STEEL.questions,
                fields: {
                  ...prevValue.STEEL.questions.fields,
                  CHECK_FILE: {
                    ...prevValue.STEEL.questions.fields.CHECK_FILE,
                    value: {
                      ...prevValue.STEEL.questions.fields.CHECK_FILE.value,
                      key: 'NO',
                      label: strings.newForm.context.noOptionsLabel,
                      value: 'NO',
                    },
                  },
                  SPECIFICATION_DATE: {
                    ...prevValue.STEEL.questions.fields.SPECIFICATION_DATE,
                    display: true,
                  },
                },
              },
            },
          }));
        }
      }

      response.forEach((item: FieldsData) => {
        if (item.key === 'SPECIFIC_SPECIFICATION' && item.step === 'STEEL') {
          setFormResult(prevValue => ({
            ...prevValue,
            [item.step]: {
              ...prevValue[item.step],
              questions: {
                ...prevValue[item.step].questions,
                fields: {
                  ...prevValue[item.step].questions.fields,
                  SPECIFIC_SPECIFICATION_REVISION_NORM: {
                    ...prevValue.STEEL.questions.fields.SPECIFIC_SPECIFICATION_REVISION_NORM,
                    value: item.value,
                    display: true,
                    conditional: [
                      ...prevValue.STEEL.questions.fields.SPECIFIC_SPECIFICATION_REVISION_NORM.conditional,
                      { key: 'DISPLAY_REVISION_NORM', value: 'true' },
                      { key: 'DISPLAY_NEW_PRODUCT', value: 'true' },
                    ],
                  },
                },
              },
            },
          }));
        } else if (item.key === 'DIAMETER') {
          setFormResult(prevValue => ({
            ...prevValue,
            [item.step]: {
              ...prevValue[item.step],
              questions: {
                ...prevValue[item.step].questions,
                fields: {
                  ...prevValue[item.step].questions.fields,
                  [item.key]: {
                    ...item,
                    display: true,
                  },
                },
              },
            },
          }));
        } else {
          setFormResult(prevValue => ({
            ...prevValue,
            [item.step]: {
              ...prevValue[item.step],
              questions: {
                ...prevValue[item.step].questions,
                fields: {
                  ...prevValue[item.step].questions.fields,
                  [item.key]: {
                    ...item,
                    display: item.key !== 'SPECIFICATION_KEY' ? true : false,
                    conditional:
                      item.key !== 'SPECIFICATION_KEY'
                        ? [
                            { key: 'DISPLAY_REVISION_NORM', value: 'true' },
                            { key: 'DISPLAY_NEW_PRODUCT', value: 'true' },
                          ]
                        : [],
                  },
                },
              },
            },
          }));
        }
      });
      if (response.length === 1) {
        show('warning', strings.newForm.messages.readNormWarning);
      } else {
        show('success', strings.newForm.messages.readNormSuccess);
      }
    }
  }

  function handleSendFileError() {
    LogAnalytics.submit({
      tipo: 'ErroLeituraNormaForm',
    });
    show('alert', strings.newForm.messages.readNormError);
    if (currentFlow === 'REVISION_NORM') {
      setFormResult(prevValue => ({
        ...prevValue,
        REVISION: {
          ...prevValue.REVISION,
          questions: {
            ...prevValue.REVISION.questions,
            fields: {
              ...prevValue.REVISION.questions.fields,
              CHECK_FILE_REVISION_NORM: {
                ...prevValue.REVISION.questions.fields.CHECK_FILE_REVISION_NORM,
                value: {
                  ...prevValue.REVISION.questions.fields.CHECK_FILE_REVISION_NORM.value,
                  key: 'NO',
                  label: strings.newForm.context.noOptionsLabel,
                  value: 'NO',
                },
              },
              SPECIFIC_SPECIFICATION_REVISION_NORM: {
                ...prevValue.REVISION.questions.fields.SPECIFIC_SPECIFICATION_REVISION_NORM,
                display: true,
              },
              SPECIFICATION_REVISION_REVISION_NORM: {
                ...prevValue.REVISION.questions.fields.SPECIFICATION_REVISION_REVISION_NORM,
                display: true,
              },
              SPECIFICATION_DATE_REVISION_NORM: {
                ...prevValue.REVISION.questions.fields.SPECIFICATION_DATE_REVISION_NORM,
                display: true,
              },
            },
          },
        },
      }));
    } else if (formResult.CLIENT.questions.fields.FLOW.value.key === 'NEW_PRODUCT') {
      setFormResult(prevValue => ({
        ...prevValue,
        STEEL: {
          ...prevValue.STEEL,
          questions: {
            ...prevValue.STEEL.questions,
            fields: {
              ...prevValue.STEEL.questions.fields,
              CHECK_FILE: {
                ...prevValue.STEEL.questions.fields.CHECK_FILE,
                value: {
                  ...prevValue.STEEL.questions.fields.CHECK_FILE.value,
                  key: 'NO',
                  label: strings.newForm.context.noOptionsLabel,
                  value: 'NO',
                },
              },
              SPECIFIC_SPECIFICATION_REVISION_NORM: {
                ...prevValue.STEEL.questions.fields.SPECIFIC_SPECIFICATION_REVISION_NORM,
                display: true,
              },
              SPECIFICATION_REVISION: {
                ...prevValue.STEEL.questions.fields.SPECIFICATION_REVISION,
                display: true,
              },
              SPECIFICATION_DATE: {
                ...prevValue.STEEL.questions.fields.SPECIFICATION_DATE,
                display: true,
              },
            },
          },
        },
      }));
    }
  }

  function handleSuccessCallBack(response) {
    if (response.field === 'STEEL_NAME' && currentFlow === 'NEW_PRODUCT') {
      setResponseCallback(response.list);
      if (response.list.length === 0) {
        show('warning', strings.newForm.context.messages.steelNotFound);
        setFormResult(prevValue => ({
          ...prevValue,
          STEEL: {
            ...prevValue.STEEL,
            questions: {
              ...prevValue.STEEL.questions,
              fields: {
                ...prevValue.STEEL.questions.fields,
                CHECK_FILE: {
                  ...prevValue.STEEL.questions.fields.CHECK_FILE,
                  display: true,
                },
              },
            },
          },
        }));
      }

      setFormResult(prevValue => ({
        ...prevValue,
        STEEL: {
          ...prevValue.STEEL,
          questions: {
            ...prevValue.STEEL.questions,
            fields: {
              ...prevValue.STEEL.questions.fields,
              STEEL_NAME: {
                ...prevValue.STEEL.questions.fields.STEEL_NAME,
                display: true,
                options: response.list,
              },
            },
          },
        },
      }));
    } else if (response.field === 'STEEL_SEARCH') {
      setResponseCallback(response.list);

      if (response.list.length === 0) {
        show(
          'warning',
          `${strings.newForm.context.messages.steelSearching} ${formResult.STEEL.questions.fields.STEEL_NAME.value}`,
        );
        setFormResult(prevValue => ({
          ...prevValue,
          STEEL: {
            ...prevValue.STEEL,
            questions: {
              ...prevValue.STEEL.questions,
              fields: {
                ...prevValue.STEEL.questions.fields,
                NORM_INTERNATIONAL: {
                  ...prevValue.STEEL.questions.fields.NORM_INTERNATIONAL,
                  display: true,
                },
              },
            },
          },
        }));
      } else {
        const steelSuggestion = response?.list[0]?.label.toLowerCase();
        const steelClient = formResult?.STEEL?.questions?.fields?.STEEL_NAME?.value.toLowerCase();

        setSteelSearchProv(response.list[0].label);
        setFormResult(prevValue => ({
          ...prevValue,
          STEEL: {
            ...prevValue.STEEL,
            questions: {
              ...prevValue.STEEL.questions,
              fields: {
                ...prevValue.STEEL.questions.fields,
                NORM_INTERNATIONAL: {
                  ...prevValue.STEEL.questions.fields.NORM_INTERNATIONAL,
                  detail:
                    steelSuggestion !== steelClient
                      ? strings.newForm.context.messages.steelSuggestion(
                          formResult.STEEL.questions.fields.STEEL_NAME.value,
                          response.list[0].label,
                        )
                      : '',
                  display: true,
                },
              },
            },
          },
        }));
      }
    } else if (response.field === 'CHEMICAL_COMPOSITION') {
      if (response.data.length === 0) {
        show('warning', strings.newForm.context.messages.CQNotFound);
        openModals('CHEMICAL_COMPOSITION');
      } else {
        formResult.STEEL.questions.fields.CHEMICAL_COMPOSITION.display = true;
        formResult.STEEL.questions.fields.CHEMICAL_COMPOSITION.readBy = 'GERDAU';
        formResult.STEEL.questions.fields.CHEMICAL_COMPOSITION.value = response.data;
      }
    } else if (response.field === 'NORM_INTERNATIONAL') {
      if (response.data.length === 0) {
        show('alert', strings.newForm.context.messages.CQNotFound);
        openModals('CHEMICAL_COMPOSITION');
      }
    } else if (response.field === 'SPECIFIC_SPECIFICATION') {
      setNormInfos(response.data);

      const options = [
        ...response.options,
        {
          key: 'OTHER',
          label: strings.newForm.context.messages.otherOptionsLabel,
          value: 'OTHER',
          conditional: conditionalAways,
        },
      ];

      setFormResult(prevValue => ({
        ...prevValue,
        STEEL: {
          ...prevValue.STEEL,
          questions: {
            ...prevValue.STEEL.questions,
            fields: {
              ...prevValue.STEEL.questions.fields,
              SPECIFIC_SPECIFICATION: {
                ...prevValue.STEEL.questions.fields.SPECIFIC_SPECIFICATION,
                display: true,
                options: options,
                value: null,
              },
            },
          },
        },
      }));
    } else if (response.field === 'LENGTH') {
      response.data.forEach((field: FieldsData) => {
        setFormResult(prevValue => ({
          ...prevValue,
          PRODUCT: {
            ...prevValue.PRODUCT,
            questions: {
              ...prevValue.PRODUCT.questions,
              fields: {
                ...prevValue.PRODUCT.questions.fields,
                [field.key]: {
                  ...prevValue.PRODUCT.questions.fields[field.key],
                  value: field.value,
                  readBy: 'GERDAU',
                },
              },
            },
          },
        }));
      });
    } else if (response.field === 'BENDING') {
      response.data.forEach(field => {
        const step = field.key === 'ROUNDNESS' ? 'ADDITIONAL' : 'PRODUCT';

        setFormResult(prevValue => ({
          ...prevValue,
          [step]: {
            ...prevValue[step],
            questions: {
              ...prevValue[step].questions,
              fields: {
                ...prevValue[step].questions.fields,
                [field.key]: {
                  ...prevValue[step].questions.fields[field.key],
                  value: field.value,
                  readBy: 'GERDAU',
                },
              },
            },
          },
        }));
      });
    } else if (response.field === 'LENGTH_MULTIPLES') {
      hideLoading('LENGTH_MULTIPLES', 'ADDITIONAL');

      setFormResult(prevValue => ({
        ...prevValue,
        ADDITIONAL: {
          ...prevValue.ADDITIONAL,
          questions: {
            ...prevValue.ADDITIONAL.questions,
            fields: {
              ...prevValue.ADDITIONAL.questions.fields,
              LENGTH_MULTIPLES: {
                ...prevValue.ADDITIONAL.questions.fields.LENGTH_MULTIPLES,
                min: response.data.min,
                max: response.data.max,
                loading: false,
              },
            },
          },
        },
      }));
    }
  }

  function handleErrorCallback() {
    resetCallBack();
  }

  function handleSettled(data, error, variables, context) {
    if (data || error || context) {
      if (error && variables.payload.field === 'SPECIFIC_SPECIFICATION') {
        show('alert', strings.newForm.context.messages.normNotFound);
      } else if (error && variables.payload.field === 'STEEL_NAME') {
        show('alert', strings.newForm.context.messages.errorSteelList);
      } else if (error && variables.payload.field === 'LENGTH') {
        show('alert', strings.newForm.context.messages.errorLength);
      }
    }
  }

  const handleFocusClient = value => {
    const hasCode = value.split(' - ');
    if (typeof hasCode[0] !== 'number') {
      setFieldValue('');
    }
  };

  const deleteClient = () => {
    setFieldValue('');
    setShowList(false);
  };

  const submitModals = (stepKey: string, key: string, values: any) => {
    let val = values.value;
    if (key === 'CHEMICAL_COMPOSITION') {
      val = values;
    }

    if (formResult[stepKey]?.questions.fields[key]) {
      setFormResult(prevValue => ({
        ...prevValue,
        [stepKey]: {
          ...prevValue[stepKey],
          questions: {
            ...prevValue[stepKey].questions,
            fields: {
              ...prevValue[stepKey].questions.fields,
              [key]: {
                ...prevValue[stepKey].questions.fields[key],
                min: values?.min,
                max: values?.max,
                display:
                  (key.includes('DIAMETER_') && !key.includes('TOLERANCE')) ||
                  key.includes('SIDE_') ||
                  key.includes('WIDTH_') ||
                  key.includes('THICKNESS_') ||
                  key.includes('AMOUNT_') ||
                  key.includes('LENGTH_')
                    ? false
                    : true,
                value: val,
              },
            },
          },
        },
      }));
    } else {
      setFormResult(prevValue => ({
        ...prevValue,
        [stepKey]: {
          ...prevValue[stepKey],
          questions: {
            ...prevValue[stepKey].questions,
            fields: {
              ...prevValue[stepKey].questions.fields,
              display:
                (key.includes('DIAMETER_') && !key.includes('TOLERANCE')) ||
                key.includes('SIDE_') ||
                key.includes('WIDTH_') ||
                key.includes('THICKNESS_') ||
                key.includes('AMOUNT_') ||
                key.includes('LENGTH_')
                  ? false
                  : true,
              [key]: values,
            },
          },
        },
      }));
    }
  };

  const findClient = (value: ClientList) => {
    if (value) {
      if (value?.formatList) {
        return value.formatList.toLowerCase().includes(fieldValue.toLowerCase());
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const filterClientList = (list: ClientList[]) => {
    return list.filter(findClient);
  };

  const handleSelectClient = (value: ClientList) => {
    setCurrentCorporateGroup(value.corporateGroup);
    setMarketClient(value.market);

    setFormResult(prevValue => ({
      CLIENT: {
        ...prevValue.CLIENT,
        questions: {
          ...prevValue.CLIENT.questions,
          fields: {
            ...prevValue.CLIENT.questions.fields,
            CLIENT_CUSTOM: {
              ...prevValue.CLIENT.questions.fields.CLIENT_CUSTOM,
              value: null,
            },
            CLIENT: {
              ...prevValue.CLIENT.questions.fields.CLIENT,
              value: value.name,
              display: true,
            },
            CLIENT_CODE: {
              ...prevValue.CLIENT.questions.fields.CLIENT_CODE,
              value: value.bp,
              display: false,
            },
            CORPORATE_CODE: {
              ...prevValue.CLIENT.questions.fields.CORPORATE_CODE,
              value: value.corporateGroup,
              display: false,
            },
            FLOW: {
              ...prevValue.CLIENT.questions.fields.FLOW,
              value: null,
            },
          },
        },
      },
    }));

    if (formResult?.CLIENT?.questions?.fields?.FLOW.value?.value) {
      callFlows(formResult?.CLIENT?.questions?.fields?.FLOW.value?.value);
    }

    setEnableClientSearch(false);
    setFieldValue(value.formatList);
    setShowList(false);
    setInitialized(true);
  };

  const scrollTo = (name?: string, session?: string, openClose?: boolean, container?: string) => {
    scroller.scrollTo(session || name, {
      duration: 500,
      delay: 0,
      smooth: 'easeInOutQuart',
      containerId: container ? container : 'scrollContainer',
    });

    const section = document.getElementById(session || name);

    if (section.tagName.toLowerCase() === 'details') {
      if (section.hasAttribute('open') && !openClose && openClose === undefined) {
        if (!session) {
          section.removeAttribute('open');
        }
      } else {
        section.setAttribute('open', 'open');
      }
    }
  };

  const [modalToOpen, setModalToOpen] = React.useState<string>(null);
  const openModals = (item: any) => {
    switch (item) {
      case 'STRETCH':
      case 'AREA_SHRINK':
      case 'YIELD_LIMIT':
      case 'LOAD_LIMIT':
        setModalToOpen('MECHANICAL_PROPERTIES');
        break;
      default:
        setModalToOpen(item);
    }
  };

  function calcMandatory(steps: FieldsData[]) {
    let totalMandatory = 0;
    let totalMandatoryLeft = 0;
    let prevValue = 0;

    steps.forEach((field: FieldsData) => {
      if (field.mandatory && field.display && !field.value) {
        totalMandatoryLeft++;
      }
      if (field.mandatory && field.display) {
        totalMandatory++;
      }
    });
    prevValue = Math.ceil(parseFloat((100 - (totalMandatoryLeft * 100) / totalMandatory).toFixed(0)));

    return +prevValue;
  }

  function checkRulesToDelete(field) {
    let additionalAttributes = [];
    if (formResult?.PRODUCT && 'PRODUCT' in formResult.PRODUCT.questions.fields) {
      additionalAttributes = Object.keys(formResult.PRODUCT.questions?.fields).filter(
        item =>
          item.includes('DIAMETER') ||
          item.includes('SIDE') ||
          item.includes('LENGTH') ||
          item.includes('WIDTH') ||
          item.includes('THICKNESS') ||
          item.includes('AMOUNT'),
      );
    }

    const list = attributesToDelete(field, additionalAttributes);
    if (list.length > 0) {
      list.forEach((item: { key: string; step: string; hideQuestion?: string }) => {
        if (formResult[item.step]?.questions?.fields[item.key]?.value) {
          const displayUpdate = item.hideQuestion === 'YES' ? false : true;
          setFormResult(prevValue => ({
            ...prevValue,
            [item.step]: {
              ...prevValue[item.step],
              questions: {
                ...prevValue[item.step].questions,
                fields: {
                  ...prevValue[item.step].questions.fields,
                  [item.key]: {
                    ...prevValue[item.step].questions.fields[item.key],
                    value: null,
                    display: displayUpdate,
                  },
                },
              },
            },
          }));
        }
      });
    }
  }

  function focusAttribute(attrKey?: string) {
    if (attrKey) {
      const div = document.getElementById(`field-${attrKey}`);
      if (div) {
        div.focus();

        div.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }

  const strings = FormStrings[userInfo.language];

  const value = {
    formResult,
    setFormResult,
    handleValueAutocompleteChange,
    enableClientSearch,
    setEnableClientSearch,
    showList,
    setShowList,
    fieldValue,
    setFieldValue,
    conversationId,
    setConversationId,
    userInfo,
    strings,
    showModalConfirmation,
    setShowModalConfirmation,
    chatCreateV2,
    setChatCreateV2,
    showModalErroNlu,
    setShowModalErroNlu,
    initialized,
    setInitialized,
    show,
    dataClientsList,
    loadingClients,
    postFile,
    loadingPostFile,
    filterClientList,
    handleFocusClient,
    deleteClient,
    handleSelectClient,
    dataFlowClient,
    dataFlowSteel,
    dataFlowProduct,
    dataFlowGoal,
    dataFlowClientManual,
    dataFlowRevisionNorm,
    dataFlowAdditional,
    loadingFlowClient,
    loadingFlowSteel,
    loadingFlowProduct,
    loadingFlowGoal,
    loadingFormFlowAdditional,
    loadingFlowClientManual,
    loadingFlowRevisionNorm,
    callFlows,
    scrollTo,
    openModals,
    modalToOpen,
    setModalToOpen,
    submitModals,
    callBackAttribute,
    responseCallback,
    loadingCallback,
    loadingCallbackSteel,
    normInfos,
    normInfosSelected,
    setNormInfosSelected,
    showLoading,
    calcMandatory,
    setNormInfos,
    checkRulesToDelete,
    callBacksShorter,
    loadingCallbackShorter,
    loadingCallbackNorm,
    callBacksNorm,
    loadingCallbackChemicalComposition,
    callBacksChemicalComposition,
    callBacksBending,
    loadingCallbackBending,
    additionalDiameters,
    setAdditionalDiameters,
    callBacksSteel,
    currentFlow,
    setCurrentFlow,
    steelSearchProv,
    fileProv,
    setFileProv,
    focusAttribute,
    marketClient,
    setMarketClient,
    hasTokenChecked,
    setHasTokenChecked,
    loadingClientBps,
  };

  const memoizedValue = React.useMemo(() => value, [value]);

  return <FormContext.Provider value={memoizedValue}>{props.children}</FormContext.Provider>;
};
