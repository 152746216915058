import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import {
  AnalysisResume,
  CreateOrderParams,
  CreateOrderPhase,
  DimensionOption,
  ProductDimensionOrder,
} from '@app/models/analysis.model';
import { quoteIsInternalUserSelector } from '@app/modules/auth/store/auth.selectores';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { FlashMessageTargetName } from '@app/providers';
import { Grid } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { Modal } from '@atomic/obj.modal/modal.component';
import { useGetClientsByBP } from '../../form/hooks/formFlow.datasource';
import { useAnalysis } from '../../hooks/useAnalysis';
import { usePostOrder } from '../../hooks/usePostOrder';
import { OrderFormStrings } from '../../quote.string';
import { CreateOrderModalBody } from './create-order-modal-body/create-order-modal-body.component';
import { CreateOrderModalFooter } from './create-order-modal-footer/create-order-modal-footer.component';
import { CreateOrderModalHeader } from './create-order-modal-header/create-order-modal-header.component';
import { CreateOrderModalShimmer } from './create-order-modal.shimmer';
import { getDimensionOptions, getProductTypeAndShape, mapProductDimensionOrderToRequest } from './utils/utils';
import { WarningMEPlaceholder } from './warning-me.placeholder';

interface CreateOrderModalProps {
  title: string;
  item?: AnalysisResume;
  opened: boolean;
  isAnalysisPage: boolean;
  onClose: () => void;
  setOpenOrderAnalysis: (open: boolean) => void;
}

export const CreateOrderModal: React.FC<CreateOrderModalProps> = ({
  title,
  item,
  opened,
  isAnalysisPage,
  onClose,
  setOpenOrderAnalysis,
}) => {
  const { userInfo, azureToken, token } = useSelector((state: RootState) => state.auth);
  const [quoteItem, setQuoteItem] = React.useState<AnalysisResume>();
  const [productDimensionOrders, setProductDimensionOrders] = React.useState<ProductDimensionOrder[]>([]);
  const [productTypeAndShape, setProductTypeAndShape] = React.useState<string>('');
  const [dimensionOptions, setDimensionOptions] = React.useState<DimensionOption[]>([]);
  const [phase, setPhase] = React.useState<string>(CreateOrderPhase.Edit);
  const [dataClientParam, setDataClientParam] = React.useState<any>();
  const [bpNumber, setBpNumber] = React.useState<string>('');
  const quoteIsInternalUser = useSelector(quoteIsInternalUserSelector);
  const { mutate: postOrder, isLoading: postOrderLoading } = usePostOrder(handlePostOrderSuccess, handlePostOrderError);
  const filtersParams = {
    sort: 'desc',
    myAnalysis: false,
    myClients: false,
    skip: 0,
    limit: 1,
  };
  const filters = { id: isAnalysisPage ? window.location.pathname.split('/').pop() : item?.id };
  const { data: dataAnalysisList } = useAnalysis({
    filtersParams,
    filters,
    quoteIsInternalUser,
    enabled: isAnalysisPage && opened,
    language: userInfo.language,
    authorizationToken: azureToken,
    accessToken: token,
  });

  const handleCallClientRequest = () => {
    if (
      (isAnalysisPage &&
        opened &&
        dataAnalysisList &&
        dataAnalysisList.length > 0 &&
        dataAnalysisList[0].client.code) ||
      (!isAnalysisPage && opened && item.client.code)
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (isAnalysisPage && opened && dataAnalysisList && dataAnalysisList.length > 0) {
      setBpNumber(dataAnalysisList[0].client.code);
    } else {
      setBpNumber(item?.client?.code);
    }
  }, [item?.client?.code, dataAnalysisList]);

  const { isFetching: fetchingClient } = useGetClientsByBP(
    {
      bp: bpNumber,
      language: userInfo.language,
      enabled: handleCallClientRequest(),
      language: userInfo.language,
      authorizationToken: azureToken,
      accessToken: token,
    },
    data => setDataClientParam(data),
  );

  const [show] = useFlashMessage(FlashMessageTargetName.APP);
  const Strings = OrderFormStrings[userInfo.language].labels.tableHeader;

  const handleClose = () => {
    setBpNumber('');
    setDataClientParam(null);
    setProductDimensionOrders([]);
    setPhase(CreateOrderPhase.Edit);
    onClose();
  };

  const handleCreate = () => {
    const mappedOrders = mapProductDimensionOrderToRequest(productDimensionOrders);

    const createOrderParams: CreateOrderParams = {
      customerName: userInfo.name,
      customerEmail: userInfo.email,
      corpGroup: '',
      companyName: item ? item.client.name : quoteItem.client.name,
      requestOrigin: 'cotacao',
      orderID: '',
      codeMaterial: '',
      customerId: dataClientParam && dataClientParam?.length > 0 ? dataClientParam[0].bp : '',
      customerCnpj: item ? item.client.cnpj : quoteItem.client.cnpj,
      implantation: mappedOrders,
      language: userInfo.language,
      authorizationToken: azureToken,
      accessToken: token,
    };

    postOrder(createOrderParams);
  };

  function handlePostOrderSuccess() {
    handleClose();
    setOpenOrderAnalysis(true);
    setDataClientParam(null);
  }

  function handlePostOrderError() {
    show('alert', Strings.error);
  }

  useEffect(() => {
    if (!item && dataAnalysisList && dataAnalysisList.length > 0 && !productTypeAndShape) {
      setQuoteItem(dataAnalysisList[0]);
      const productTypeShape = getProductTypeAndShape(
        dataAnalysisList[0].product?.type,
        dataAnalysisList[0].product?.shape,
      );
      setProductTypeAndShape(productTypeShape);
    }

    if (!item && quoteItem && productTypeAndShape) {
      const dimensionOptionAux = getDimensionOptions(productTypeAndShape, quoteItem, userInfo.language);
      setDimensionOptions(dimensionOptionAux);
    }

    if (item) {
      const quoteTypeShape = getProductTypeAndShape(item.product?.type, item.product?.shape);
      setProductTypeAndShape(quoteTypeShape);
      const quoteDimensionOptions = getDimensionOptions(quoteTypeShape, item, userInfo.language);
      setDimensionOptions(quoteDimensionOptions);
    }
  }, [item, dataAnalysisList, productTypeAndShape]);

  return (
    <Modal
      small={dataClientParam && dataClientParam.length > 0 && dataClientParam[0].market === 'ME'}
      opened={opened}
      onClose={handleClose}
      overflowX='hidden'
    >
      <LoadingState
        loading={fetchingClient}
        error={dataClientParam && dataClientParam.length > 0 && dataClientParam[0].market === 'ME'}
      >
        <LoadingState.Shimmer>
          <CreateOrderModalShimmer />
        </LoadingState.Shimmer>
        <LoadingState.Error></LoadingState.Error>

        {dataClientParam && dataClientParam.length > 0 && dataClientParam[0].market === 'ME' ? (
          <WarningMEPlaceholder onClose={handleClose} />
        ) : (
          <Grid fluid>
            <CreateOrderModalHeader
              title={title}
              phase={phase}
              item={item ? item : quoteItem}
              dimensionOptions={dimensionOptions}
              productDimensionOrders={productDimensionOrders}
              setProductDimensionOrders={setProductDimensionOrders}
            />
            <CreateOrderModalBody
              phase={phase}
              item={item ? item : quoteItem}
              clientDetail={dataClientParam}
              productDimensionOrders={productDimensionOrders}
              setProductDimensionOrders={setProductDimensionOrders}
            />
            <CreateOrderModalFooter
              isLoading={postOrderLoading}
              phase={phase}
              productDimensionOrders={productDimensionOrders}
              setPhase={setPhase}
              onCreate={handleCreate}
              onClose={() => handleClose()}
            />
          </Grid>
        )}
      </LoadingState>
    </Modal>
  );
};
