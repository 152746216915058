/* eslint-disable complexity */

import React from 'react';
import { useDispatch } from 'react-redux';
import { AppThunkDispatch } from '@app/core/redux/store';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { validateTokens } from '@app/modules/components/notification-center/store/thunk/validate-and-get-notification.thunk';
import { FlashMessageTargetName } from '@app/providers';
import { SelectField } from '@atomic/atm.select';
import { TextField } from '@atomic/atm.text-field';
import { Body } from '@atomic/atm.typography';
import { Form, Validators } from '@atomic/obj.form';
import { LoadingState } from '@atomic/obj.loading-state';
import { FormContext } from '../../form.context';
import { FormFlowProps } from '../form-flow-fields.container';
import { FormTextFieldWrapper, FormUnitWrapper } from '../result/form-result.component.style';

export const TextDefaultField = (props: FormFlowProps) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { formResult, strings, callBackAttribute, showLoading } = React.useContext(FormContext);
  const [unit, setUnit] = React.useState(
    props.field?.unit?.allowed?.length > 1 ? props.field?.unit?.allowed[0]?.symbol : props.field?.unit?.symbol,
  );

  const [prevValue, setPrevValue] = React.useState(null);
  const [show] = useFlashMessage(FlashMessageTargetName.APP);

  const stringsNewForm = strings.newForm.messages;

  const input: any = document.getElementById(`${props.field.key}`);

  if (input && input.type === 'number') {
    input.addEventListener('wheel', event => {
      event.preventDefault();
    });
  }

  React.useEffect(() => {
    setPrevValue(props?.field?.value);
  }, [props?.field?.value]);

  const getValues = () => {
    if (prevValue) {
      checkCallback(prevValue);
      props.handleValue(prevValue, 'text', props.field.key, props.stepKey, unit);
    }
  };

  const checkCallback = value => {
    if (props.field.key === 'LENGTH_MULTIPLES') {
      if (formResult?.PRODUCT?.questions?.fields?.LENGTH?.value) {
        const lengthField = formResult?.PRODUCT?.questions?.fields?.LENGTH?.value.split(' a ');
        dispatch(validateTokens()).then(() => {
          const payload = {
            length: { min: parseFloat(lengthField[0]), max: parseFloat(lengthField[1]) },
            version: 1,
            multiple: parseFloat(value),
            field: 'LENGTH_MULTIPLES',
            step: 'ADDITIONAL',
          };
          const url = formResult[props.stepKey].questions.fields[props.field.key].callback;
          callBackAttribute(payload, url, 'getLengthMultiples');
          showLoading(props.field.key, props.stepKey);
        });
      } else {
        show('alert', stringsNewForm.alertEmptyLength);
      }
    }
  };

  return (
    <LoadingState loading={props?.field?.loading}>
      <Form.Field
        name={props.field.key}
        validators={props?.field?.mandatory ? [Validators.Required(stringsNewForm.mandatoryAlert)] : []}
        value={prevValue}
        onValueChange={value => setPrevValue(value)}
      >
        <FormTextFieldWrapper upperCase={props.field.key === 'CLIENT_CUSTOM'}>
          <TextField
            id={props.field.key}
            type={props.field.type}
            noArrows={props.field.type === 'number'}
            onBlur={() => getValues()}
            maxLength={props.field.key === 'CNPJ' ? 20 : 999}
          ></TextField>

          {props.field?.unit?.allowed?.length > 1 ? (
            <FormUnitWrapper>
              <Form.Field
                name={props.field.key}
                onValueChange={value => setUnit(value)}
                validators={[Validators.Required(stringsNewForm.mandatoryAlert)]}
                value={unit}
              >
                <SelectField id={`${props.field.key}-unit`} onBlur={() => getValues()}>
                  {props.field?.unit.allowed.map((el: { symbol: string; description: string }) => (
                    <option value={el.symbol} key={el.symbol}>
                      {el.description}
                    </option>
                  ))}
                </SelectField>
              </Form.Field>
            </FormUnitWrapper>
          ) : (
            <FormUnitWrapper>
              <Body>
                {formResult[props.stepKey]?.questions?.fields[props.field?.key]?.unit?.allowed[0].description}
              </Body>
            </FormUnitWrapper>
          )}
        </FormTextFieldWrapper>
      </Form.Field>
    </LoadingState>
  );
};
