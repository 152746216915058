import React from 'react';
import { Button } from '@atomic/atm.button';
import { TextAreaField, TextField } from '@atomic/atm.text-field';
import { Body, H2 } from '@atomic/atm.typography';
//import { DefaultListCell } from '@atomic/mol.default-list-cell';
import { DefaultListCell } from '@atomic/mol.default-list-cell';
import { Tab } from '@atomic/mol.tab';
import { Hbox } from '@atomic/obj.box';
import { Form, Validators } from '@atomic/obj.form';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
//import { Visibility } from '@atomic/obj.visibility';
import { Visibility } from '@atomic/obj.visibility';
import { FormContext } from '../form.context';
import {
  AttributesContentWrapperStyled,
  AttributesTabsWrapperStyled,
} from './modal-aditional-attributes.component.style';

export interface Attributes {
  key: string;
  label: string;
  data: string[];
  ButtonsList_PropriedadesMecanicas: string[];
  ButtonsList_PropriedadesMetalurgicas: string[];
  ButtonsList_Dimensional: string[];
  ButtonsList_OutrasCaracteristicas: string[];
  ButtonsList_TiposdeEnsaio: string[];
  ButtonsList_Produto: string[];
}

interface ModalAttributeProps {
  open: boolean;
  submitModals: (stepKey: string, key: string, values: any) => void;
}

export const ModalAditionalAttributes: React.FC<ModalAttributeProps> = props => {
  const { setModalToOpen, formResult, setFormResult, scrollTo, openModals, strings } = React.useContext(FormContext);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [opened, setOpened] = React.useState(props.open);
  const [selectedAttribute, setSelectedAttribute] = React.useState(null);
  const [newAttributeForm, setNewAttributeForm] = React.useState(false);

  const additionalCategories = [
    {
      key: 'DIMENTIONAL',
      label: strings.newForm.additionalAttrModal.DIMENTIONAL,
      options: [
        'DIAGONAL_DIFFERENCE',
        'BENDING',
        'CROOKED',
        'LENGTH_MULTIPLES',
        'ROUNDNESS',
        'NUMERIC_CORNER_RADIUS',
        'REDUCTION_RATE',
        'TOLERANCE',
        'TORSION',
      ],
    },
    {
      key: 'MECHANICAL_PROPERTIES',
      label: strings.newForm.additionalAttrModal.MECHANICAL_PROPERTIES,
      options: [
        'HARDNESS_HALF_RADIUS',
        'HARDNESS_THIRD_RADIUS',
        'HARDNESS_NUCLEUS',
        'HARDNESS_SUPERFICIAL',
        'LOAD_LIMIT',
        'YIELD_LIMIT',
        'AREA_SHRINK',
        'STRETCH',
        'TRACTION',
        'IMPACTO_V',
        'IMPACTO_U',
      ],
    },
    {
      key: 'METALLURGICAL_PROPERTIES',
      label: strings.newForm.additionalAttrModal.METALLURGICAL_PROPERTIES,
      options: [
        'HARDENABILITY',
        'MICRO_INCLUSION',
        'MACRO_ETCH',
        'IDEAL_CRITICAL_DIAMETER',
        'SPHEROIDIZING_DEGREE',
        'MICROSTRUCTURE',
        'PARTIAL_DECARBURIZING',
        'TOTAL_DECARBURIZING',
        'GLOBAL_DECARBURIZING',
        'MICRO_INCLUSION',
        'ASTM_DS',
        'SEGREGATION_C',
        'SEGREGATION_S',
        'AUSTENITE_GRAIN_SIZE',
      ],
    },
    {
      key: 'TYPES_OF_TEST',
      label: strings.newForm.additionalAttrModal.TYPES_OF_TEST,
      options: ['INTERNAL_FLAWS', 'SUPERFICIAL_FLAWS', 'BLUE_FRACTURE'],
    },
    {
      key: 'OTHERS',
      label: strings.newForm.additionalAttrModal.OTHERS,
      options: ['EDGE_CONDITIONING', 'COLOR', 'FREE', 'PART_NUMBER', 'BUNDLE_WEIGHT', 'CASTING'],
    },
  ];

  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };

  function removeSpecialChars(str) {
    const removedSpecialChars = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    const removedSpaces = removedSpecialChars.replace(/\s+/g, '_');
    return removedSpaces.toUpperCase();
  }

  const handleSubmit = data => {
    if (Object.keys(data.error).length > 0) {
      return;
    } else {
      const keyField = removeSpecialChars(data.data.label);
      setFormResult(prevValue => ({
        ...prevValue,
        ADDITIONAL: {
          ...prevValue.ADDITIONAL,
          questions: {
            ...prevValue.ADDITIONAL?.questions,
            fields: {
              ...prevValue.ADDITIONAL?.questions.fields,
              [keyField]: {
                ...prevValue.ADDITIONAL?.questions.fields[keyField],
                value: data.data.value,
                key: keyField,
                step: 'ADDITIONAL',
                readBy: 'user',
                question: data.data.label,
                label: data.data.label,
                permissions: {
                  visible: true,
                  editable: false,
                },
                display: true,
                type: 'text',
                otherAttribute: true,
              },
            },
          },
        },
      }));

      setSelectedAttribute(null);
      setNewAttributeForm(false);
      setOpened(false);
      setModalToOpen(null);
    }
  };

  const handleOption = (item: any) => {
    if (item === 'TRACTION') {
      setFormResult(prevValue => ({
        ...prevValue,
        ADDITIONAL: {
          ...prevValue.ADDITIONAL,
          questions: {
            ...prevValue.ADDITIONAL.questions,
            fields: {
              ...prevValue.ADDITIONAL.questions.fields,
              LOAD_LIMIT: {
                ...prevValue.ADDITIONAL.questions.fields.LOAD_LIMIT,
                display: true,
              },
              YIELD_LIMIT: {
                ...prevValue.ADDITIONAL.questions.fields.YIELD_LIMIT,
                display: true,
              },
              AREA_SHRINK: {
                ...prevValue.ADDITIONAL.questions.fields.AREA_SHRINK,
                display: true,
              },
              STRETCH: {
                ...prevValue.ADDITIONAL.questions.fields.STRETCH,
                display: true,
              },
            },
          },
        },
      }));
      setModalToOpen(null);
      setOpened(false);
    } else if (item === 'IMPACTO_V') {
      setFormResult(prevValue => ({
        ...prevValue,
        ADDITIONAL: {
          ...prevValue.ADDITIONAL,
          questions: {
            ...prevValue.ADDITIONAL.questions,
            fields: {
              ...prevValue.ADDITIONAL.questions.fields,
              IMPACTO_V: {
                ...prevValue.ADDITIONAL.questions.fields.IMPACTO_V,
                display: true,
              },
              IMPACTO_V_CELCIUS: {
                ...prevValue.ADDITIONAL.questions.fields.IMPACTO_V_CELCIUS,
                display: true,
              },
            },
          },
        },
      }));
      setModalToOpen(null);
      setOpened(false);
    } else if (item === 'IMPACTO_U') {
      setFormResult(prevValue => ({
        ...prevValue,
        ADDITIONAL: {
          ...prevValue.ADDITIONAL,
          questions: {
            ...prevValue.ADDITIONAL.questions,
            fields: {
              ...prevValue.ADDITIONAL.questions.fields,
              IMPACTO_U: {
                ...prevValue.ADDITIONAL.questions.fields.IMPACTO_U,
                display: true,
              },
              IMPACTO_U_CELCIUS: {
                ...prevValue.ADDITIONAL.questions.fields.IMPACTO_U_CELCIUS,
                display: true,
              },
            },
          },
        },
      }));
      setModalToOpen(null);
      setOpened(false);
    } else if (
      item === 'HARDENABILITY' ||
      item === 'MICRO_INCLUSION' ||
      item === 'CHEMICAL_COMPOSITION' ||
      item === 'MACRO_ETCH'
    ) {
      openModals(item);
    } else {
      setFormResult(prevValue => ({
        ...prevValue,
        ADDITIONAL: {
          ...prevValue.ADDITIONAL,
          questions: {
            ...prevValue.ADDITIONAL.questions,
            fields: {
              ...prevValue.ADDITIONAL.questions.fields,
              [item]: {
                ...prevValue.ADDITIONAL.questions.fields[item],
                display: true,
              },
            },
          },
        },
      }));
      setModalToOpen(null);
      setOpened(false);
    }

    scrollTo(item, 'ADDITIONAL');
  };

  const handleClose = () => {
    setOpened(false);
    setModalToOpen(null);
  };

  return (
    <Modal preventOverlayClick medium opened={opened} onClose={handleClose}>
      <Form onSubmit={handleSubmit}>
        {!newAttributeForm ? (
          <Grid fluid>
            <H2>{strings.modalAttributes.title}</H2>
            <AttributesTabsWrapperStyled>
              <Tab initialIndex={tabIndex} onIndexChanged={handleTabChange}>
                {additionalCategories?.map((tab: { key: string; label: string; options: string[] }) => {
                  if (tab.options.length > 0) {
                    return (
                      <Tab.Item key={tab.key}>
                        <Hbox>
                          <Hbox.Item>{tab.label}</Hbox.Item>
                        </Hbox>
                      </Tab.Item>
                    );
                  } else {
                    return '';
                  }
                })}
              </Tab>
            </AttributesTabsWrapperStyled>
            <Row>
              <Col xs={12}>
                <Form.Field
                  name='attribute'
                  initialValue={selectedAttribute}
                  validators={[Validators.Required(strings.modalAttributes.alertMandatory)]}
                >
                  {additionalCategories?.map((list: { key: string; label: string; options: string[] }, index) => {
                    if (list.options.length > 0) {
                      return (
                        <Visibility key={index} visible={tabIndex === index}>
                          <AttributesContentWrapperStyled>
                            {list.options.map(
                              (item, index2) =>
                                formResult?.ADDITIONAL?.questions?.fields[item] && (
                                  <DefaultListCell
                                    selected={selectedAttribute === item}
                                    key={index2}
                                    onClick={() => handleOption(item)}
                                  >
                                    <Body>{formResult?.ADDITIONAL?.questions?.fields[item].label}</Body>
                                  </DefaultListCell>
                                ),
                            )}
                          </AttributesContentWrapperStyled>
                        </Visibility>
                      );
                    } else {
                      return '';
                    }
                  })}
                </Form.Field>
              </Col>
            </Row>
            <Hbox>
              {tabIndex === additionalCategories.length - 1 && (
                <Hbox.Item hAlign='flex-start'>
                  <Button kind='link' onClick={() => setNewAttributeForm(true)}>
                    {strings.modalAttributes.btnAdd}
                  </Button>
                </Hbox.Item>
              )}
            </Hbox>
            <VSeparator />
          </Grid>
        ) : (
          <Grid fluid>
            <H2>{strings.modalAttributes.titleAdd}</H2>
            <Row>
              <Col xs={12}>
                <Body>{strings.modalAttributes.fieldTitle.label}</Body>
                <VSeparator small />
                <Form.Field name='label' validators={[Validators.Required(strings.modalAttributes.alertMandatory2)]}>
                  <TextField placeholder={strings.modalAttributes.fieldTitle.placeholder} />
                </Form.Field>
                <VSeparator />
              </Col>

              <Col xs={12}>
                <Body>{strings.modalAttributes.fieldValue.label}</Body>
                <VSeparator small />
                <Form.Field name='value' validators={[Validators.Required(strings.modalAttributes.alertMandatory2)]}>
                  <TextAreaField placeholder={strings.modalAttributes.fieldValue.placeholder} disableResize />
                </Form.Field>
                <VSeparator />
              </Col>
            </Row>
            <Hbox hAlign='flex-end'>
              <Hbox.Item noGrow>
                <Button kind='secondary' expanded onClick={() => setNewAttributeForm(false)}>
                  {strings.modalAttributes.btnBack}
                </Button>
              </Hbox.Item>
              <Hbox.Separator />
              <Hbox.Item noGrow>
                <Button kind='primary' expanded type='submit'>
                  {strings.modalAttributes.btnAddSubmit}
                </Button>
              </Hbox.Item>
            </Hbox>
            <VSeparator />
          </Grid>
        )}
      </Form>
    </Modal>
  );
};
